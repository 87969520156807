import { PencilAltIcon, UploadIcon, XIcon } from "@heroicons/react/outline";
import { useContext, useRef, useState } from "react";
import { ModalContext, GlobalStateContext, SignatureRequestContext } from "../contexts";
import { GlobalStateActionType } from "../reducers";
import SignatureField, { SignatreFieldRef } from "./SignatureField";

import Cropper, { ReactCropperElement } from "react-cropper";
import "cropperjs/dist/cropper.css";
import { formatDate, formatTime } from "../formatting";
import { luminance } from "../utils";
import { useTranslation } from "react-i18next";
import Checkbox from "./Checkbox";
import { FieldRegion } from "../types";

type Props = {
  visible: boolean,
  fieldRegions: FieldRegion[]
};

function SignatureModal(props: Props) {
  const { fieldRegions } = props;
  const [acceptTerms, setAcceptTerms] = useState(false);
  const { closeModal } = useContext(ModalContext);
  const { dispatch, state: globalState } = useContext(GlobalStateContext);
  const { t } = useTranslation();

  const { branding, formatting, recipientNumber } = useContext(SignatureRequestContext);

  const [inputMode, setInputMode] = useState<"draw" | "type" | "upload">("type");

  const [signatureHasDrawn, setSignatureHasDrawn] = useState(false);
  const [signatureText, setSignatureText] = useState<string>("");
  const [signatureUpload, setSignatureUpload] = useState<string>("");

  const signatureField = useRef<SignatreFieldRef>(null);
  const cropperRef = useRef<ReactCropperElement>(null);

  function handleInsert() {
    const now = new Date();

    const timeString = formatTime(now, formatting?.dateTimeFormat ?? "hh:mmaa");
    const dateString = (formatDate(now, formatting?.dateFormat ?? "MM/DD/YYYY") + " " + timeString).trim();
    const initialsField = fieldRegions.find(r => (r.type === "INITIALS" && recipientNumber === r.recipient));
    const currentInitialsField = globalState.fieldValues[initialsField?.id ?? ""];
    const initialsString = currentInitialsField ?? signatureText.split(" ").map(s => s[0]).join("").toUpperCase();
    if (inputMode === "type" || inputMode === "draw")
      dispatch({
        type: GlobalStateActionType.INSERT_SIGNATURE,
        payload: {
          signatureData: signatureField.current!.getSignatureData(),
          signatureDateString: dateString
        }
      });

    if (initialsField && inputMode === "type") {
      dispatch({ type: GlobalStateActionType.UPDATE_FIELD_VALUE, fieldId: initialsField.id, textValue: initialsString });
    }

    if (inputMode === "upload")
      dispatch({
        type: GlobalStateActionType.INSERT_SIGNATURE,
        payload: {
          signatureData: cropperRef.current!.cropper.getCroppedCanvas().toDataURL(),
          signatureDateString: dateString
        }
      });

    closeModal();
  }

  function handleUpload(event: React.ChangeEvent<HTMLInputElement>) {
    if (event.currentTarget.files?.length !== 1)
      return;

    const file = event.currentTarget.files[0];
    setSignatureUpload(URL.createObjectURL(file));
  }

  const isDisabled = (() => {
    if (inputMode === "draw")
      return !signatureHasDrawn;

    if (inputMode === "type")
      return !signatureText;

    if (inputMode === "upload")
      return !signatureUpload;
  })();

  if (!props.visible)
    return null;

  return (
    <div className="flex absolute top-0 left-0 w-full h-full z-50 backdrop-filter backdrop-brightness-[30%]">
      <div className="flex flex-col relative m-auto p-5 lg:p-8 mx-5 lg:mx-auto w-full max-w-md lg:max-w-2xl bg-white rounded-3xl shadow-lg overflow-hidden">
        <XIcon className="w-4 h-4 absolute top-7 right-7 text-gray hover:text-red cursor-pointer" onClick={closeModal} />
        <h2 className="font-title font-semibold text-xl mb-7">
          {t("Add your signature")}
        </h2>
        <div className="flex bg-[#F8F8F8] p-1 rounded-full gap-1 mb-7 text-xs">
          <button className={"flex py-2 w-full justify-center items-center rounded-full transition-colors " + (inputMode === "type" ? "bg-white shadow-md" : "bg-none")} onClick={() => setInputMode("type")}>
            {t("Type it")}
            <span className="font-title text-base ml-1">T</span>
          </button>
          <button className={"flex py-2 w-full justify-center items-center rounded-full transition-colors " + (inputMode === "draw" ? "bg-white shadow-md" : "bg-none")} onClick={() => setInputMode("draw")}>
            {t("Draw it")}
            <PencilAltIcon className="w-4 h-4 ml-1" />
          </button>
          <button className={"flex py-2 w-full justify-center items-center rounded-full transition-colors " + (inputMode === "upload" ? "bg-white shadow-md" : "bg-none")} onClick={() => setInputMode("upload")}>
            {t("Upload")}
            <UploadIcon className="w-4 h-4 ml-1" />
          </button>
        </div>
        {(inputMode === "draw" || inputMode === "type") &&
          <div className="relative mx-auto flex-shrink-0 w-full">
            <SignatureField ref={signatureField} className="shadow-inner h-36" text={signatureText} renderText={inputMode === "type"} setHasChanges={setSignatureHasDrawn} />
            {inputMode === "type" &&
              <input type="text" className="absolute top-2 left-2 right-2 px-2 py-1 outline-none border-gray border rounded shadow" value={signatureText} onChange={e => setSignatureText(e.currentTarget.value)} placeholder={t("Full Name")} autoFocus />
            }
          </div>
        }
        {inputMode === "upload" &&
          <div className="w-full flex flex-col gap-2 items-center justify-center max-w-full h-36">
            {signatureUpload &&
              <div>
                <Cropper
                  ref={cropperRef}
                  src={signatureUpload}
                  style={{ height: "130px", width: "270px", marginTop: 10 }}
                  aspectRatio={269 / 67}
                  autoCropArea={1.0}
                />
              </div>
            }
            <input className="w-9/12 lg:w-full" type="file" accept="image/png, image/jpeg" onChange={handleUpload} style={signatureUpload ? { paddingTop: 10 } : { paddingTop: 52, paddingBottom: 51.5 }} />
          </div>
        }
        <div className="flex flex-col gap-7 justify-between items-center mt-7">
          <div className="flex gap-2 items-center w-full">
            <Checkbox checked={acceptTerms} onChange={() => setAcceptTerms(prev => !prev)} />
            <p className="text-xs text-gray" onClick={() => setAcceptTerms(prev => !prev)}>{t("I understand that this is a legal representation of my signature")}</p>
          </div>
          <button className="btn btn-primary w-full rounded-full" onClick={handleInsert} style={{ backgroundColor: branding?.colour, color: luminance(branding?.colour ?? "#000000") >= 0.5 ? "black" : "white" }} disabled={isDisabled || !acceptTerms}>
            {t("Insert")}
          </button>
        </div>
      </div>
    </div>
  );
}

export default SignatureModal;