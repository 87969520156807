import { CheckCircleIcon } from "@heroicons/react/outline";
import { useTranslation } from "react-i18next";
import { SignatureRequest } from "../types";
import { useState } from "react";

function Confirmation(props: { signatureRequest: SignatureRequest, overrideToken: string | null }) {
  const { signatureRequest } = props;
  const { t } = useTranslation();
  const branding = signatureRequest?.branding;

  const [email, setEmail] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isError, setIsError] = useState(false);

  async function submitEmail() {
    setIsSubmitting(true);
    setIsError(false);

    await fetch(`${process.env.REACT_APP_API_ENDPOINT}/signatures/${props.overrideToken!}/update-email/`, {
      method: "POST",
      headers: {
        "content-type": "application/json"
      },
      body: JSON.stringify({ email })
    })
      .then((res) => res.ok ? setIsSubmitted(true) : setIsError(true))
      .finally(() => setIsSubmitting(false));
  }

  return (
    <div className="fixed flex flex-col w-full h-full">
      <div className={`flex flex-col items-center justify-center gap-4 h-full p-7 lg:p-0 ${branding?.colour ? '' : "bg-gray"}`}>
        {branding?.colour && <div className="h-screen w-screen fixed -z-10 opacity-5" style={{ backgroundColor: branding.colour }} />}
        <div className="rounded-2xl bg-white lg:shadow max-w-lg w-full p-8">
          <div className="flex flex-col gap-3.5 lg:gap-0 lg:flex-row items-center w-full py-4 px-8 z-10 mb-6 bg-[#06AB691F] text-green rounded-lg">
            <div className="flex items-center">
              <CheckCircleIcon className="w-5 h-5 mr-1" />
              <p className="font-semibold mr-3">{t("Success!")}</p>
            </div>
            <p className="font-medium text-center lg:text-left">{t("You've signed your documents.")}</p>
          </div>

          {branding?.logo
            ? <img src={branding?.logo} className="mb-4 mx-auto" width={160} alt="Brand Logo" />
            : <img src="https://static.portant.co/logo-full-blue.png" className="mb-4 mx-auto" width={160} alt="Portant Logo" />
          }

          <h2 className="text-blue font-sans text-xl font-semibold text-center mb-2">
            {t("Signing complete")}
          </h2>
          <h3 className="font-sans text-center font-semibold">
            {(props.overrideToken && !isSubmitted)
              ? <p className="text-gray">{t("Please provide your email address to receive a copy")}</p>
              : <p className="text-green">{t("You will receive a copy in your inbox shortly")}</p>
            }
          </h3>

          {props.overrideToken &&
            <div className="flex items-center gap-2 mt-5">
              <input type="text" className={`border-2 rounded-full p-2.5 px-6 w-full outline-none ${isError ? "border-red" : "border-gray"}`} disabled={isSubmitted} placeholder={t("Email")} value={email} onChange={(e) => { setEmail(e.currentTarget.value); setIsError(false) }} />
              <button className="btn btn-primary rounded-full h-full p-2.5 w-40 disabled:bg-gray" disabled={isSubmitting || isSubmitted || email.length < 5} onClick={submitEmail}>{isSubmitting ? t("Submiting...") : (isSubmitted ? t("Submitted") : t("Submit"))}</button>
            </div>
          }

          {!props.overrideToken &&
            <a className="btn bg-[#F6F6F6] w-full rounded-full mt-5 py-2.5 shadow-none" href="https://www.gmail.com" target="_blank" rel="noreferrer">
              <img src="https://storage.googleapis.com/static.portant.co/gmail-icon.svg" className="h-6 mr-1" alt="gmail" />
              {t("Open Gmail")}
            </a>
          }

        </div>
        <div className="rounded-2xl bg-white lg:shadow max-w-lg w-full p-8">
          <h2 className="text-blue font-sans text-xl font-semibold text-center mb-2">
            {t("Get your own documents eSigned")}
          </h2>
          <h3 className="text-black font-sans text-center mb-4">
            {t("Design your documents in Google Docs & Slides, then get them eSigned effortlessly with Portant.")}
          </h3>
          <a
            href="https://app.portant.co?utm_source=document-signing&utm_medium=confirmation-page&utm_campaign=signup-from-recipients"
            className="btn btn-primary rounded-full w-60 mx-auto py-2.5 shadow-none text-center"
            target="_blank"
            rel="noreferrer"
          >
            {t("Start now")}
          </a>

          <div className="flex items-center justify-center gap-2 mt-2">
            <p className="font-sans text-blue">{t("Have an account?")}</p>
            <a
              href="https://app.portant.co"
              target="_blank"
              rel="noreferrer"
              className="font-sans text-blue font-semibold"
            >
              {t("Log in")}
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Confirmation;
